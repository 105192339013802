import Cookies from "js-cookie";
import useModalsStore from "../store/modalsStore";
import i18n from "../i18n";
import { queryClient } from "..";
import useUserStore from "../store/userStore";

export const logout = () => {
    Cookies.remove("token");
    const setLoginModalOpen = useModalsStore.getState().setLoginModalOpen;
    setLoginModalOpen(true);
    window.location.href = "/";
};

export const getToken = () => {
    const token = Cookies.get("token");
    return token;
};

export const getBranchId = () => {
    const branchId = Cookies.get("branchId");
    return branchId;
};

export const getEmirateId = () => {
    const emirateId = Cookies.get("emirateId");
    return emirateId;
};

export const getRegionId = () => {
    const regionId = Cookies.get("regionId");
    return regionId;
};

export const getLanguage = () => {
    return i18n.language;
};

export const getCurrency = () => {
    const currency = Cookies.get("currency");
    return currency;
};

export const getCountry = () => {
    const country = Cookies.get("country");
    return country;
};

export const getLat = () => {
    const lat = Cookies.get("lat");
    return lat;
};

export const getLng = () => {
    const lng = Cookies.get("lng");
    return lng;
};

export const getSession = () => {
    const session = localStorage.getItem("session");
    if (!session) return null;
    const parsedSession = JSON.parse(session);
    const setUser = useUserStore.getState().setUser;
    const setMainAddress = useUserStore.getState().setMainAddress;

    setUser(parsedSession.user);
    setMainAddress(parsedSession.mainAddress);
};

export const getDefaultAddressId = () => {
    const defaultAddressId = Cookies.get("defaultAddressId");
    return defaultAddressId;
};

export const setToken = (token: string) => {
    Cookies.set("token", token);
};

export const setBranchId = (branchId: string) => {
    Cookies.set("branchId", branchId);
};

export const setEmirateId = (emirateId: string) => {
    Cookies.set("emirateId", emirateId);
};

export const setRegionId = (regionId: string) => {
    Cookies.set("regionId", regionId);
};

export const setLanguage = (language: string) => {
    Cookies.set("language", language);
};

export const setCurrency = (currency: string) => {
    Cookies.set("currency", currency);
};

export const setCountry = (country: string) => {
    Cookies.set("country", country);
};

export const setLat = (lat: string) => {
    Cookies.set("lat", lat);
};

export const setLng = (lng: string) => {
    Cookies.set("lng", lng);
};

export const invalidate = () => {
    queryClient.invalidateQueries({
        queryKey: ["global"],
    });
};

export const setSession = () => {
    const session = useUserStore.getState();
    localStorage.setItem("session", JSON.stringify(session));
};

export const setDefaultAddressId = (defaultAddressId: string) => {
    Cookies.set("defaultAddressId", defaultAddressId);
};

export const resetSession = () => {
    localStorage.removeItem("session");
    Cookies.remove("token");
    Cookies.remove("branchId");
    Cookies.remove("emirateId");
    Cookies.remove("regionId");
    Cookies.remove("language");
    Cookies.remove("currency");
    Cookies.remove("country");
    Cookies.remove("lat");
    Cookies.remove("lng");
};
