import { useEffect, useRef, useState } from "react";
import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";

export default function LanguageSwitcher() {
    const [toggle, setToggle] = useState(false);
    const { i18n } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setToggle(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div className="relative">
            <button
                className="flex items-center gap-x-2 text-[rgba(79,79,79,1)] transition hover:opacity-80"
                onClick={() => setToggle(!toggle)}
            >
                <span className="pb-0.5">
                    {i18n.language === "en" ? "English" : "العربية"}
                </span>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 10L12 15L17 10"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <div
                className={cn(
                    "absolute -right-12 top-12 z-[150] flex min-w-[250px] flex-col rounded-2xl bg-white p-6 text-xl font-semibold shadow-[0px_10px_50px_-5px_rgba(0,0,0,0.06),0px_-4px_20px_-5px_rgba(0,0,0,0.06)] after:absolute after:-top-2 after:right-[43px] after:size-8 after:-rotate-45 after:rounded-tr-md after:border-r-4 after:border-t-4 after:border-white after:bg-white after:content-[''] xl:top-9 rtl:-left-0 rtl:right-auto rtl:after:left-[43px] rtl:after:right-auto rtl:xl:-left-12",
                    {
                        hidden: !toggle,
                    },
                )}
                ref={ref}
            >
                <button
                    dir="ltr"
                    className={cn(
                        "p-4 text-start transition hover:text-primary",
                        {
                            "text-primary": i18n.language === "en",
                        },
                    )}
                    onClick={() => {
                        i18n.changeLanguage("en");
                        setToggle(false);
                    }}
                >
                    English
                </button>
                <button
                    dir="rtl"
                    className={cn(
                        "p-4 text-start transition hover:text-primary",
                        {
                            "text-primary": i18n.language === "ar",
                        },
                    )}
                    onClick={() => {
                        i18n.changeLanguage("ar");
                        setToggle(false);
                    }}
                >
                    العربية
                </button>
            </div>
        </div>
    );
}
