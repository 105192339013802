// ComingSoon.js
import React from "react";
import "./ComingSoon.css"; // Add your CSS for styling
import HeaderLogo from "../../assets/header-logo";
import Logo from "../../assets/logo";

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <div className="content">
                <div
                    style={{
                        display: "grid",
                        placeItems: "center",
                        height: "20vh",
                    }}
                >
                    <Logo />
                </div>

                <h1>Coming Soon...</h1>
                <p>
                    Our website is under construction, but we’ll be ready soon!
                </p>
            </div>
        </div>
    );
};

export default ComingSoon;
