import { type ClassValue, clsx } from "clsx";
import { md5 } from "js-md5";
import { twMerge } from "tailwind-merge";
import i18n from "../i18n";
import Cookies from "js-cookie";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function md5Encrypt(text: string) {
    return md5(text + "Jjwar@W");
}

export const toArabic = (numString: string) => {
    if (i18n.language === "en") return numString;
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return numString.replace(/\d/g, (digit) => arabicNumbers[Number(digit)]);
};

export const getToken = () => {
    return Cookies.get("token");
};
