import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "../components/loading-screen";
import axios from "axios";

export default function PrivacyPolicy() {
    const { t } = useTranslation();
    return (
        <div className="mx-auto flex max-w-[885px] flex-col items-center gap-10 px-4 py-[60px]">
            <h2 className="text-[28px] font-medium text-[rgba(23,23,23,1)]">
                PRIVACY POLICY
            </h2>
            <div className="reset-tw">
                <div className="privacy-policy">
                    <p>
                        WE, ALTHAHAB ALLAME COMMERCIAL BROKERS – L.L.C – O.P.C
                        (“THAHABI”, “We”, “Us” or “Our”), we carry out our
                        business from our retail stores (under our brand
                        "Thahabi") and our website (www.thahabi.net) and our
                        mobile app (Thahabi) (collectively "Platform"). THAHABI
                        acts as a link between the customer and the store, and
                        registers customers' orders for stores within the
                        controls of the privacy policy and terms and conditions,
                        and coordinates with stores to receive orders and send
                        them for delivery to customers within a list of options
                        and recommendations within the application.
                    </p>
                    <p>
                        WE are committed to the compliance of applicable Privacy
                        Laws in the country where THAHABI’s product is
                        distributed. Protecting the privacy and security of your
                        personal data is of prime importance to THAHABI,
                        therefore, we conduct our business by abiding by the
                        laws applicable on data privacy and data security in the
                        country where THAHABI product is distributed to you.
                    </p>
                    <p>
                        This Privacy Policy is intended to inform you about the
                        personal data that is collected from you, how your
                        personal data may be used and disclosed, how you can
                        control the use of your personal data, and how your
                        personal data is protected when you use THAHABI
                        Application or the application developed by a third
                        party for THAHABI (“Application”). We do not sell or
                        trade your personally identifiable information; however,
                        we may share the same with third parties who assist us
                        in conducting our business, operating our
                        website/Application, or servicing you, so long as those
                        parties agree to keep this information confidential.
                    </p>
                    <p>
                        Please note that this Privacy Policy may be amended or
                        updated from time to time to reflect changes in our
                        practices with respect to processing personal data or
                        changes in applicable law. We encourage you to read this
                        Privacy Policy carefully, and to regularly check it to
                        review any changes that we might make to it.
                    </p>
                    <p>
                        By accessing the services provided by the Platform, you
                        agree to the collection, use, sharing and storage of
                        your information by Thahabi in the manner provided in
                        this Privacy Policy.
                    </p>
                    <h3>What Information is collected about you:</h3>
                    <p>Information you provide directly:</p>
                    <p>
                        When you use certain functions provided by the
                        Application, such as when you register for certain
                        services, access, features or services, we may ask you
                        to provide certain personal data such as: name, contact
                        number, postal address, email address, username,
                        password, your opinion on services, product, features,
                        etc.
                    </p>
                    <h3>
                        Information that is automatically collected when you
                        visit the Application:
                    </h3>
                    <p>
                        a. When you download and visit the application, certain
                        information may be collected automatically, which
                        includes: <br />
                        <br />
                        b. Your computer’s Internet Protocol (IP) Address,{" "}
                        <br />
                        <br />
                        c. Your browser type and operating system, <br />
                        <br />
                        d. Web pages and links you visit, advertisements you
                        view,
                        <br />
                        <br />
                        e. Your bandwidth speed and information about the
                        software programs that are installed on your computer,
                        <br />
                        <br />
                        f. Standard server log information <br />
                        <br />
                        g. Geo Location Information: When you use our app
                        THAHABI or any related services, we may collect
                        information about your geolocation via third party
                        services to notify you with customized offers.
                    </p>
                    <p>
                        If you make any purchase, we collect sensitive personal
                        data in connection with the purchase. This data includes
                        your payment data, such as your credit or debit card
                        number and other card information or bank/ account
                        details, and other account and authentication
                        information, as well as billing, shipping, and contact
                        details.
                    </p>
                    <p>
                        We may collect additional information in connection with
                        your participation in any schemes, promotions or
                        contests offered by us and information you provide when
                        giving us feedback or completing profile forms. We may
                        also monitor customer traffic patterns and Platform use,
                        which enable us to improve the service we provide to
                        customers.
                    </p>
                    <p>
                        <strong>
                            Information that we collect from other sources:
                        </strong>{" "}
                        We may receive information about you from publicly and
                        commercially available sources (as permitted by law),
                        which we may combine with other information we receive
                        from or about you. We also may receive information about
                        you from third-party social networking services when you
                        choose to connect with those services.
                    </p>
                    <h3>How we use the information we collect:</h3>
                    <p>
                        General: THAHABI may use the information collected from
                        you to register you or your device for a Service,
                        collection of email enables us to improve your shopping
                        experience, to provide a service or feature you request,
                        to provide customized content and make recommendations
                        based on your past activities on our Application.
                        Further, we use your personal information and your
                        contact information to send you registration
                        confirmation, special offers, recommendations (based on
                        your previous orders, your browsing history and your
                        interests),
                    </p>
                    <p>
                        Advertisements: THAHABI may use your information to
                        provide you with product and service updates,
                        newsletters and other communications about existing
                        and/or new products and services by post, email,
                        telephone, in-device messaging and/or text message
                        (SMS), if you have provided your prior consent or we are
                        otherwise permitted to do so under applicable law.
                    </p>
                    <p>
                        Statistics and Research: THAHABI may use your
                        information to create user group profiles or segment
                        data and to otherwise create anonymous, aggregated
                        statistics about the use of our products, services and
                        loyalty programs, which we may share with third parties
                        and/or make available to the public.
                    </p>
                    <p>
                        Product/Services Improvement: THAHABI may use your
                        information to improve and enhance existing THAHABI
                        stores and products, services and Applications and
                        develop new offerings, recommendations, advertisements
                        and other communications and learn more about customers’
                        preferences in general. Publish your reviews, comments
                        and content: Where you have uploaded product reviews,
                        comments or content to our websites or Application and
                        made them publicly visible, THAHABI may link to, publish
                        or publicize these materials elsewhere including in our
                        own advertisements.
                    </p>
                    <p>
                        All personal data and information collected by us is
                        used only for the purpose specified in this Privacy
                        Policy.
                    </p>
                    <h3>To whom do we disclose your Personal Data:</h3>
                    <p>
                        a. We may employ third party entities and individuals to
                        facilitate our Services (e.g. maintenance, analysis,
                        audit, marketing, and development). These third parties
                        have limited access to your information only to perform
                        these tasks on our behalf and are obligated to THAHABI,
                        not to disclose or use it for other purposes. All of our
                        approved third-party providers go through an extensive
                        data protection compliance vetting process before being
                        selected as a third party provider and are bound by all
                        relevant data privacy laws, terms of confidentiality,
                        and this Privacy Policy. <br />
                        <br />
                        b. If you choose to use the Application, you will be
                        asked to confirm acceptance of the third-party developer
                        user agreement and THAHABI privacy policy. <br />
                        <br />
                        c. There may be instances when we disclose your
                        information to other parties to comply with the law or
                        respond to compulsory legal process or to verify or
                        enforce compliance with the policies governing our
                        Services. We believe that disclosure is necessary to
                        protect our rights, property, or safety of THAHABI, or
                        any of our respective affiliates, business partners, or
                        customers and/or comply with a judicial proceeding,
                        legal process or a court order served on us.
                    </p>
                    <p>
                        We do not sell, share, or distribute any personal
                        information with third parties for their own marketing
                        or advertising purposes without your prior express
                        consent.
                    </p>
                    <h3>Your Choices:</h3>
                    <p>
                        We offer a variety of choices about how we use your
                        information. You can make choices about whether to
                        receive promotional communications from us by following
                        the unsubscribe instructions included in the
                        communication. In addition, you can also generally make
                        choices in your settings, or by visiting relevant links.
                    </p>
                    <h3>Security:</h3>
                    <p>
                        The security of your personal data is of great
                        importance to us. We have put in place reasonable
                        physical and technical measures to safeguard the
                        information we collect in connection with the Services.
                        However, please note that although we take reasonable
                        steps to protect your information, no
                        Application/website, Internet transmission, computer
                        system or wireless connection is completely secure.
                    </p>
                    <h3>Links to Other Web Sites:</h3>
                    <p>
                        THAHABI Application may contain links to other websites.
                        THAHBI is not responsible for the privacy practices or
                        the content of other websites or applications. On our
                        Application, we may have links to other websites/
                        applications or you are referred to our Application
                        through a link from another website or Application.
                        However, please note that once you have used these links
                        to leave our site, we do not have control over those
                        websites. We cannot be responsible for the privacy
                        policies and practices of other websites or
                        applications. Such content is subject to their terms of
                        use and any additional guidelines and privacy
                        information provided in relation to that use on their
                        website or application. We recommend that you check the
                        policy of each website/application you visit to better
                        understand your rights and obligations especially when
                        you are submitting any type of content on those third
                        party website/application. Please contact the owner or
                        operator of such website/application if you have any
                        concerns or questions.
                    </p>
                    <h3>Your Consent</h3>
                    <p>
                        By using our Application and/or by providing your
                        information, you consent to the collection, sharing,
                        storage and use of the information you disclose on the
                        Platform in accordance with the Privacy Policy.
                    </p>
                    <h3>Your Rights</h3>
                    <p>
                        You have the right to access or correct the personal
                        information that we collect. You are also entitled to
                        restrict or object, at any time, to the further
                        processing of your personal information. You may write
                        to us at care@thahabi.net regarding the personal
                        information collected by us.
                    </p>
                    <p>
                        For any of the uses of your personal information
                        described in the Privacy Policy that require your
                        consent, note that you may withdraw your consent by
                        writing to us at the above-mentioned email address. In
                        the event you refuse to share any information or
                        withdraw consent to process information that you have
                        previously given to us, we reserve the right to restrict
                        or deny access to the Platform and the provision of our
                        services for which we consider such information to be
                        necessary.
                    </p>
                    <h3>Data Retention</h3>
                    <p>
                        We store and retain the personal data provided by the
                        users for such period of time as is required to fulfil
                        the purposes for which such information is collected, as
                        outlined in this Privacy Policy, subject to such longer
                        periods of retention as may be required under applicable
                        laws.
                    </p>
                    <h3>Changes to the Privacy Policy:</h3>
                    <p>
                        We may update this Privacy Policy at any time to reflect
                        changes in our practices and service offerings. If we
                        modify this Privacy Policy, we will update the date on
                        which the new Privacy Policy will be effective. We will
                        notify you of any material changes in the way we treat
                        your information by placing a prominent notice on our
                        website/Application.
                    </p>
                </div>
            </div>
        </div>
    );
}
