import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "../components/loading-screen";
import axios from "axios";

export default function TermsConditions() {
    const { t } = useTranslation();
    return (
        <div className="mx-auto flex max-w-[885px] flex-col items-center gap-10 px-4 py-[60px]">
            <h2 className="text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {t("terms-and-conditions")}
            </h2>
            <div
                className="reset-tw"
            >
                <div className="terms-and-conditions">
                    <h2>TERMS AND CONDITIONS</h2>
                    <p>
                        This App is owned and operated by ALTHAHAB ALLAME
                        COMMERCIAL BROKERS – L.L.C – O.P.C (“THAHABI”, “We”,
                        “Us” or “Our”), having its registered office at (UAE-
                        Abu Dhabi - Al Salam Street - Al Danah - Zone 1 E9_02 -
                        Al Ferdous Tower – Floor 5 - Flat 503s).
                    </p>
                    <p>
                        Please, read these Terms and Conditions carefully before
                        using the App. In accessing and using the services
                        provided by our application, you signify your consent
                        and agreement to be bound by the Terms and Conditions
                        specified here. THAHABI reserves the right to change
                        these terms and conditions from time to time without any
                        obligation to inform you, and it is your responsibility
                        to check for the recent updates. Any updates to these
                        Terms will take effect immediately after the date of
                        posting and you agree to be bound by the terms of these
                        updates and amendments.
                    </p>
                    <h2>ELIGIBILITY CRITERIA</h2>
                    <p>
                        If you are below 15 years of age, you are prohibited to
                        use/purchase/contract from or with this App.
                    </p>
                    <p>
                        Those who choose to access this Application from outside
                        UAE are responsible for compliance with local laws to
                        the extent local laws are applicable.
                    </p>
                    <h2>PRICING AND PAYMENT</h2>
                    <h3>Domestic Orders</h3>
                    <p>
                        The price of gold is determined based on the global
                        market price, so the prices on the application are
                        subject to change without notice. And you can find out
                        or access the price of gold through the application, for
                        the price of jewelry as listed on the day of purchase,
                        in addition to the value added tax for the UAE, which is
                        5% of the total price.
                    </p>
                    <p>
                        For orders being shipped within UAE, payment only in DHs
                        is accepted.
                    </p>
                    <p>
                        In case international credit cards are being used for
                        domestic orders, the transaction amount will be
                        converted to DHs before the payment is accepted.
                        Currency conversion charges may apply according to your
                        credit card policy. You may be required to share with us
                        a copy of any Government issued photo identity proof of
                        the country of residence as mentioned below:
                    </p>
                    <ul>
                        <li>Passport with Visa Copy</li>
                        <li>Driving license</li>
                        <li>Employment card</li>
                        <li>
                            Any other government issued ID proof of the country
                            of residence
                        </li>
                    </ul>
                    <h2>DELIVERY SCHEDULE</h2>
                    <h3>Domestic Orders</h3>
                    <p>
                        The initial delivery date or time will be specified
                        through the Notifications page via the app. The exact
                        delivery date shall be provided as soon as your order is
                        ready for dispatch. Alternatively, registered customers
                        can track their order by clicking Track Order and find
                        the order status.
                    </p>
                    <p>
                        <strong>PLEASE NOTE:</strong> Customer needs to produce
                        any of the following Government issued photo identity
                        proof for receiving the delivery:
                    </p>
                    <ul>
                        <li>Identification card</li>
                        <li>Passport with Visa Copy</li>
                        <li>Driving License</li>
                    </ul>
                    <p>
                        <strong>NOTE:</strong>
                        <br />
                        In case of international order, customer has to pay the
                        shipping charges at the time of placing the order. Also,
                        custom duty and taxes levied by destination country has
                        to be borne by the customer and needs to be paid to the
                        logistic partner at the time of delivery. Please note
                        that all consignments are on Delivery Duty Unpaid (DDU)
                        basis.
                    </p>
                    <h2>TRACK ORDER</h2>
                    <p>
                        All registered customers have the option to track their
                        orders by clicking on <a href="/">Track Order</a>.
                    </p>
                    <h2>YOUR OBLIGATIONS</h2>
                    <p>
                        a) You have to provide true, accurate, current and
                        complete information about yourself while registration
                        on <a href="www.thahabi.net">www.thahabi.net</a> or via
                        the app. <br />
                        <br />
                        b) You should maintain and promptly update registration
                        data to keep it true, accurate, current and complete.
                        THAHABI reserves the right to confirm and validate the
                        information and other details provided by you at any
                        point in time. Further, THAHABI also reserves the right
                        to ask for additional information for accepting orders
                        or before making delivery in certain cases. If upon
                        confirmation, your details are found to be false
                        (partially or completely) or if you provide any
                        information that is false, inaccurate, incomplete or not
                        current or if THAHABI has reasonable grounds to suspect
                        that such information is false, inaccurate, incomplete,
                        not current or not in accordance with these Terms and
                        Conditions, we have the right to indefinitely suspend or
                        terminate your membership and refuse to provide you with
                        access to our Application and Website and other
                        affiliated websites without any prior intimation
                        whatsoever.
                        <br />
                        <br />
                        c) You confirm that the address at which delivery of the
                        product(s) ordered by you is to be made is correct and
                        proper in all aspects. In the event that a non-delivery
                        occurs on account of a mistake by you (i.e. wrong name
                        or address or any other wrong information), any extra
                        cost incurred by THAHABI for re-delivery shall be
                        claimed from you and you shall bear such expenses.
                        <br />
                        <br /> d) Before placing an order, you shall check the
                        product description carefully. By placing an order for a
                        product, you agree to be bound by the conditions of sale
                        and invitation to offer included in the item's
                        description.
                        <br />
                        <br /> e) If there is any delay in the delivery caused
                        due to any force majeure event including without
                        limitation natural calamity, governmental actions,
                        pandemic, public holiday or any other unforeseen
                        circumstance, then THAHABI shall not be liable for such
                        delay in the delivery of the product. f) Any product you
                        buy from our application is completely at your
                        discretion and you accept that you place the order only
                        after thoroughly inquiring about the product and being
                        completely acquainted about the product, its features,
                        characteristics, usage, etc. THAHABI is not liable for
                        any kind of damages or losses whatsoever suffered by the
                        customers due to the use of the product. This is
                        applicable for both domestic and international orders.
                    </p>
                    <h2>RESTRICTION</h2>
                    <p>
                        THAHABI reserves the right, at its sole discretion, to
                        limit the quantity/value of the items purchased by you
                        and such other restrictions as may be applicable to
                        orders placed by the same account, the same credit/debit
                        card and also to orders that use the same billing and/
                        or shipping address. We will provide notification to the
                        customer should such limits be applied. THAHABI reserves
                        the right at its sole discretion to prohibit sales to
                        anyone as it may deem fit.
                    </p>
                    <h2>CANCELLATION BY OUR COMPANY</h2>
                    <p>
                        There may be certain orders that THAHABI cannot accept,
                        and therefore, we reserve the right, at our sole
                        discretion, to refuse or cancel any order. Some reasons
                        may include limitation on quantity available for
                        purchase, errors in pricing or product information or
                        certain issues identified by our fraud avoidance
                        department or any other issue which THAHABI identifies
                        for not accepting the order.
                    </p>
                    <p>
                        We also reserve the right to ask for additional
                        information for accepting orders in certain cases. We
                        will notify you in case your order has been cancelled
                        fully or partially or if any additional information is
                        required to accept your order.
                    </p>
                    <h3>CANCELLATION BY CUSTOMER</h3>
                    <p>
                        Once an order is placed, it cannot be canceled via the
                        application, but if the customer wishes to cancel, he
                        must contact customer service via the number 600584224
                        and inform them of the reason for the cancellation to do
                        the necessary. Once the cancellation request is
                        received, if any payments have been received, we will
                        initiate the process of refunding the amount to your
                        account via the same payment mode that you had used to
                        make the transaction or through cheque (amount would be
                        refunded through cheque, only in cases where the
                        debit/credit card used by customer while placing the
                        order is not in use). Once the refund process is
                        initiated, you will receive a refund within a period of
                        7 to 15 business days in your account or through a
                        check. For more details on refunds, please see the
                        Refunds section of these Terms and Conditions.
                    </p>
                    <p>
                        <strong>NOTE:</strong>
                        <br /> In the event that the amount has been deducted
                        from your account but the transaction failed, we will
                        refund the amount to your account at the earliest.
                    </p>
                    <h2>RETURN POLICY</h2>
                    <p>
                        THAHBI is committed to ensuring full customer
                        satisfaction with respect to the products available on
                        our website or our application. However, if you are not
                        happy with the product, you can choose to return the
                        order in accordance with the Terms and Conditions
                        contained herein.
                    </p>
                    <p>
                        <h3>7- days Return Policy for Domestic orders only</h3>
                        <br />
                        We have a specialized team to monitor, inspect and
                        confirm orders and in case the delivered order may be in
                        damaged condition, or not the same item(s) as you
                        ordered, you can return the product unused and in the
                        same condition as you received it, in its original
                        packaging, along with the invoice. Our courier partner
                        will collect the order from the address provided by you
                        and will get it back to us without any charges or
                        delivery fees. <br />
                        <h3>International Orders</h3> <br />
                        <strong>NOTE:</strong>
                        <br /> In case of international orders, we do not
                        currently accept any returns or exchange of our
                        products. In case a defective product has been received,
                        you are required to report the defect within 7 days of
                        receipt of the product by writing to us on e-mail:
                        care@thahabi.net
                    </p>
                    <h2>RETURN CHARGES </h2>
                    <h3>Domestic Order:</h3>
                    <p>
                        Our logistics service provider will collect the order
                        from provided address (within UAE). Customers need to
                        pay shipping charges to return the product which is 20
                        DHs.
                    </p>
                    <h2>REFUND</h2>
                    We are providing this service for one time per purchase and
                    the refunds on your purchase are done seamlessly once you
                    send your request for a refund. We shall process the refund
                    once we receive the product and ensure that the returned
                    item is in unused condition, in its original packaging along
                    with its invoice. Then, we will return the amount to your
                    wallet inside the application, and you will be able to use
                    it again at THAHBI Application. However, the coupon might
                    take a few days to be issued and sent to you. The company
                    does not hold any responsibility for the items broken after
                    sales.
                    <h3>How to Initiate the Refund Process?</h3>
                    1. Customers should contact THAHABI customer support service
                    on 600584224 or inform us by WhatsApp or write to
                    care@thahabi.net within 7 days from the receipt of product.
                    <br />
                    2. Customers should not return the product before receiving
                    a confirmation message or mail from THAHABI about the
                    delivery of the same.
                    <br />
                    3. All the products must be returned in their original
                    condition, unaltered and unused in its original packaging.
                    <br />
                    4. Provide the invoice into the package for return process.
                    Without the above, returns will not be processed. <br />
                    <br />
                    <strong>NOTE:</strong>{" "}
                    <h3>
                        We shall not entertain any request after 7 days from the
                        date of receipt of the product
                    </h3>
                    <h2>SITE SECURITY</h2>
                    <p>
                        {" "}
                        You are prohibited from violating or attempting to
                        violate the security of this website, including, without
                        limitation: <br />
                        a) Accessing data not intended for you or logging onto a
                        server or an account which you are not authorized to
                        access. <br />
                        b) Attempting to probe, scan or test the vulnerability
                        of a system or network or to breach security or
                        authentication measures without proper authorization.{" "}
                        <br />
                        c) Attempting to interfere with service to any other
                        user, host or network, including without limitation via
                        means of submitting a virus to the site, overloading,
                        flooding, spamming, mail-bombing or crashing. <br />
                        d) Sending unsolicited email, including promotions and
                        or advertising of products or services;
                        <br />
                        e) Forging any TCP / IP packet header or any part of the
                        header information in any email or newsgroup, posting
                        violations of system or network security may result in
                        civil or criminal liability. THAHABI will investigate
                        occurrences that may involve such violations and we will
                        cooperate with law enforcement authorities in
                        prosecuting users who are involved in such violations.
                        You agree not to use any device, software or routine to
                        interfere or attempt to interfere with the proper
                        working of this site or any activity being conducted on
                        this site.
                    </p>
                    <h2>PRIVACY POLICY</h2>
                    <p>
                        Please see our <a href="/privacy-policy">Privacy Policy</a> for
                        information regarding how your information is used,
                        collected, stored, and disclosed.
                    </p>
                    <h2>FRAUDULENT TRANSACTIONS</h2>
                    <p>
                        THAHABI reserves the right to recover the cost of goods,
                        collection charges, damages caused to THAHABI and legal
                        expenses from persons using this Application
                        fraudulently. THAHABI reserves the right to initiate
                        legal proceedings against such persons for fraudulent
                        use of the Application and any other unlawful acts or
                        acts or omissions in breach of these Terms and
                        Conditions.
                    </p>
                    <h2>DISCLAIMER</h2>
                    <p>
                        THAHBI specifically does not represent or warrant that
                        the Application materials or any portion of our services
                        are accurate, complete, reliable, current or error-free.
                        THAHABI does not represent or warrant that the
                        Application or its servers are free of viruses or other
                        harmful components.
                    </p>
                    <p>
                        We have made every effort to display the colors and
                        sizes of our products that appear on our Application as
                        accurately as possible. However, as the actual colors
                        you see will depend on your monitor. We cannot guarantee
                        that your monitor's display of any color will accurately
                        reflect the color of the product on delivery. Also,
                        packaging of the product may vary from that displayed on
                        the Application.
                    </p>
                    <p>
                        If any part of this site disclaimer is found to be
                        invalid by law, the rest of them remain valid and
                        enforceable.
                    </p>
                    <h2>WEBSITE CONTENT</h2>
                    <p>
                        THAHABI reserves all intellectual property rights in all
                        text, programs, products, processes, technology, content
                        images, photographs, videos, video clips, music clips,
                        illustrations, designs, icons, programs, downloads and
                        any other materials (collectively “Content”), which
                        appear on this Application. Access to this Application
                        does not authorize anyone to use any Content, name, logo
                        or mark in any manner. You may print or download the
                        Contents displayed on the Application for your personal
                        and non-commercial use only. No right, title or interest
                        in any Content is transferred to you as a result of any
                        such downloading, printing or copying. You will not
                        reproduce, publish, transmit, distribute, display,
                        modify, create derivative works from, sell or
                        participate in any sale of or exploit in any way, in
                        whole or in part, any of the Content of the Application
                        or any related software. All software used on this
                        Application is the property of THAHABI or its
                        licensors/suppliers and they are protected by UAE and
                        international copyright laws.
                    </p>
                    <h2>EXTERNAL MATERIAL AND LINKS</h2>
                    <p>
                        The website or the app may contain links to other
                        websites; however, please note that once you use these
                        links to leave our site, we do not control those sites.
                        These external sites are not under our control. We
                        cannot be held responsible for such sites and we cannot
                        make any guarantees about them. We provide these links
                        because we think they may be of interest to you, but we
                        do not monitor or endorse these sites.
                    </p>
                    <h2>REVIEWS, FEEDBACK, SUBMISSIONS</h2>
                    <p>
                        All reviews, comments, feedback, postcards, suggestions,
                        ideas and other submissions disclosed, submitted or
                        offered in connection with your use of this Application
                        will be and will remain the property of THAHABI. Such
                        disclosure, submission or offer of any comments shall
                        constitute an assignment to THAHABI of all worldwide
                        rights, titles and interests in all copyrights and other
                        intellectual properties in the comments. Thus, THAHABI
                        owns exclusively all such rights, titles and interests
                        and shall not be limited in any way in its use,
                        commercial or otherwise of any comments, suggestions,
                        reviews and feedback. THAHABI will be entitled to use,
                        reproduce, disclose, modify, adapt, create derivative
                        works from, publish, display and distribute any
                        comments, suggestions, reviews or feedback you submit
                        for any purpose whatsoever, without restriction and
                        without compensating you in any way.
                    </p>
                    <h3>
                        THAHABI will be under no obligation to: a) To maintain
                        any comments in confidence
                    </h3>
                    <p>
                        a) To maintain any comments in confidence <br />
                        b) To pay you any compensation for any comments <br />
                        c) To respond to any comments
                    </p>
                    <p>
                        You agree and confirm that any comments, suggestions,
                        reviews or feedback submitted by you to us or on the
                        Application will not violate these Terms and Conditions,
                        our Privacy Policy or any right of any third party,
                        including copyright, trademark, privacy or other
                        personal or proprietary right(s) and will not cause
                        injury to any person or entity. You further agree that
                        no comments submitted by you to the Application will be
                        or contain libelous or otherwise unlawful, threatening,
                        abusive or obscene material, or contain software
                        viruses, political campaigning, commercial solicitation,
                        chain letters, mass mails or any form of spam. THAHABI
                        does not regularly review posted comments, but does
                        reserve the right (but not the obligation) to monitor
                        and edit or remove any comments submitted to this
                        Application. You grant THAHBi the right to use the name
                        that you submit in connection with any comments. You
                        agree not to use a false email address, impersonate any
                        person or entity, or otherwise mislead as to the origin
                        of any comments you submit. You are and shall remain
                        solely responsible for the content of any comments,
                        suggestions, reviews or feedback you make and you
                        further agree to keep indemnified THAHABI and its
                        affiliates for all claims resulting from any comments,
                        suggestions, reviews or feedback submitted by you.
                        THAHABI and its affiliates are not responsible nor
                        assume any liability for any comments, suggestions ,
                        reviews or feedback submitted by you or any third party.
                    </p>
                    <h2>FORCE MAJEURE</h2>
                    <p>
                        We are not liable for any delay in the performance or
                        non-performance of any of our obligations hereunder and
                        shall not be liable for any loss or damages caused
                        thereby where the same is occasioned by any cause
                        whatsoever that is beyond our control including but not
                        limited to an act of God, war, civil disturbance, riots,
                        strikes, lock-outs, governmental or parliamentary
                        restrictions, prohibitions or enactments of any kind,
                        pandemic, import or export regulations, exchange control
                        regulations or accident or non-availability/ delay in
                        transport.
                    </p>
                    <h2>TERMINATION</h2>
                    <p>
                        THAHABI may at any time terminate or suspend your access
                        to and use of the Application and may do so immediately
                        without notice, and accordingly deny you access to this
                        Application. Any termination of your access to the
                        Application shall not cancel your obligation to pay for
                        the product(s) already ordered from the Application or
                        affect any liability against you that may have arisen
                        under these Terms and Conditions.
                    </p>
                    <h2>GOVERNING LAW AND JURISDICTION</h2>
                    <p>
                        These Terms and Conditions shall be construed in
                        accordance with the applicable laws of UAE. The courts
                        shall have exclusive jurisdiction in any proceedings
                        arising out of these Terms and Conditions. These terms
                        are an official document of established legal authority.
                    </p>
                    <h2>MODIFICATION OF TERMS AND CONDITIONS</h2>
                    <p>
                        THAHBI reserves the right at any time to modify these
                        Terms and Conditions at any time without any prior
                        notification to you. You can access the latest version
                        of the Terms and Conditions at any given time on the
                        Website or Application. You should regularly view the
                        Terms and Conditions In the event the modified Terms and
                        Conditions are not acceptable to you, you may choose not
                        to visit or use the Application or the Website. However,
                        if you continue to access, view or use our service of
                        the Application or the Website, you shall be deemed to
                        have agreed and accepted to the modified Terms and
                        Conditions and you undertake to abide by the modified
                        Terms and Conditions.
                    </p>
                </div>
            </div>
        </div>
    );
}
