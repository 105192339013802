import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Country, Language, User } from "../types/user";
import { Address } from "../actions/getAddresses";

type UserStore = {
    user: User | null;
    language: Language;
    country: Country;
    region: Region | null;
    emirate: Emirate | null;
    branch: Branch | null;
    latLng: { lat: number; lng: number } | null;
    changeAddress: Address | null;
    mainAddress: Address | null;
    setLanguage: (language: Language) => void;
    setCountry: (country: Country) => void;
    setUser: (user: User | null) => void;
    setRegion: (region: Region | null) => void;
    setEmirate: (emirate: Emirate | null) => void;
    setBranch: (branch: Branch | null) => void;
    setLatLng: (latLng: { lat: number; lng: number }) => void;
    setChangeAddress: (changeAddress: Address | null) => void;
    setMainAddress: (mainAddress: Address | null) => void;
};

const useUserStore = create<UserStore>()(
    devtools(
        (set) => ({
            language: "en",
            country: "uae",
            user: null,
            region: null,
            emirate: null,
            branch: null,
            latLng: null,
            changeAddress: null,
            mainAddress: null,
            setLanguage: (language) => set({ language }),
            setCountry: (country) => set({ country }),
            setUser: (user) => set({ user }),
            setRegion: (region) => set({ region }),
            setEmirate: (emirate) => set({ emirate }),
            setBranch: (branch) => set({ branch }),
            setLatLng: (latLng) => set({ latLng }),
            setChangeAddress: (changeAddress) => set({ changeAddress }),
            setMainAddress: (mainAddress) => set({ mainAddress }),
        }),
        {
            enabled: true,
            name: "user-store",
        },
    ),
);
export default useUserStore;

export type Region = {
    name: string;
    id: string;
};
export type Emirate = {
    name: string;
    id: string;
};

export type Branch = {
    name: string;
    id: string;
};
